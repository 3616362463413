.dndflow {
  /* border: 2px solid red; */
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 15% 68%;
  grid-template-areas:
    "navbar"
    "body";
}
.slick-track {
  display: flex !important;
}
#body {
  grid-area: body;
  height: 100%;
  width: 100%;
}
.sidebar {
  grid-area: sidebar;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
}
.slider-item1 {
  position: relative;
  margin: 0 10%;
  height: 100%;
}
#navbar {
  grid-area: navbar;
  /* background-color: #d8f89b; */
  /* border: 2px solid rgb(121, 62, 230); */
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.navbar-Text {
  width: 20vh;
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translate(-50%, -50%);
  padding-left: 28px;
}

.body-backbtn {
  position: absolute;
  top: 19%;
  left: 1.5%;
  z-index: 5500;
  display: inline-block;
}
.body-nextbtn {
  position: absolute;
  top: 20.5%;
  right: 3%;
  z-index: 5500;
  display: inline-block;
}
.erasebtn {
  height: 12%;
  position: absolute;
  top: 29%;
  right: 2.5%;
  z-index: 5500;
  display: inline-block;
}
.fullscreen {
  height: 12%;
  position: absolute;
  top: 39%;
  right: 2.5%;
  z-index: 5500;
  display: inline-block;
}
.footer-backbtn {
  position: absolute;
  top: 60%;
  left: 5%;
  transform: translate(-50%, -50%);
  width: 11vh;
}
.footer-nextbtn {
  position: absolute;
  top: 60%;
  left: 95%;
  transform: translate(-50%, -50%);
  width: 11vh;
}
.dndflow aside {
  border-right: none;
  padding: none;
  font-size: 12px;
  background: none;
  position: relative;
  transform: translate(0px, -23px);
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}
.dndnode_beepertrue {
  background-image: url("/src/Assets/Tronix_updated/beeper.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_beeperfalse {
  display: none;
}

.dndnode_capacitor1000true {
  background-image: url("/src/Assets/Tronix_updated/capacitor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_capacitor1000false {
  display: none;
}

.dndnode_capacitor100true {
  background-image: url("/src/Assets/Tronix_updated/capacitor100.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_capacitor100false {
  display: none;
}

.dndnode_diodetrue {
  background-image: url("/src/Assets/Tronix_updated/diode.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_diodefalse {
  display: none;
}
.dndnode_diptrue {
  background-image: url("/src/Assets/Tronix_updated/dip switch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_dipfalse {
  display: none;
}
.dndnode_junctiontrue {
  background-image: url("/src/Assets/Tronix_updated/junction.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_junctionfalse {
  display: none;
}
.dndnode_ldrtrue {
  background-image: url("/src/Assets/Tronix_updated/ldr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_ldrfalse {
  display: none;
}
.dndnode_ledtrue {
  background-image: url("/src/Assets/Tronix_updated/led.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_ledfalse {
  display: none;
}
.dndnode_pottrue {
  background-image: url("/src/Assets/Tronix_updated/pot.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_potfalse {
  display: none;
}
.dndnode_powertrue {
  background-image: url("/src/Assets/Tronix_updated/power.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
  border: "2px solid red";
}
.dndnode_powerfalse {
  display: none;
}
.dndnode_res_100true {
  background-image: url("/src/Assets/res_100_ohm.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_res_100false {
  background-image: url("/src/Assets/res_100_ohm.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_res_250true {
  background-image: url("/src/Assets/Tronix_updated/resistor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_res_250false {
  display: none;
}
.dndnode_tacttrue {
  background-image: url("/src/Assets/Tronix_updated/tact.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_tactfalse {
  display: none;
}
.dndnode_timer_ictrue {
  background-image: url("/src/Assets/Tronix_updated/timer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_timer_icfalse {
  display: none;
}
.dndnode_transistortrue {
  background-image: url("/src/Assets/Tronix_updated/transistor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_transistorfalse {
  display: none;
}
.dndnode_two_way_switchtrue {
  background-image: url("/src/Assets/Tronix_updated/twowayswitch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  margin-bottom: 20px;
  transform: scale(1);
  left: 3vw;
  position: relative;
  transform: translate(10px, 10px);
}
.dndnode_two_way_switchfalse {
  position: none;
}
.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
  grid-area: reactflow-wrapper;
}

.dndflow .selectall {
  margin-top: 10px;
}

.Image-render {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12vh;
  width: 11vw;
  position: relative;
  z-index: -4;
  transform: scale();
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output {
  padding: 0 !important;
  background: none !important;
  border: none !important;
  width: 1px !important;
  height: 1px !important;
  z-index: -1 !important;
}
.side {
  /* width: 80%;
    height: 25%; */
  margin: 0 10%;
  display: flex;
  align-items: center;
  justify-items: flex-start;
  background: #b1b1b747;
  border-radius: 12px;
}
.react-flow__handle-top {
  display: none;
}
.react-flow__edge-path {
  stroke: #09b8a7 !important;
  stroke-width: 4 !important;
}
.led-light {
  height: 35px;
  width: 50;
  width: 35px;

  border-radius: 50%;
  display: inline-block;
  left: 4.6vw;
  top: -7.5vh;
  position: relative;
  background-color: red;
  opacity: 0.6;
  transform: scale(1);
}
.beeper {
  height: 57px;

  width: 57px;

  border-radius: 50%;
  display: inline-block;
  left: 4vw;
  top: -12.7vh;
  position: relative;
  background-color: red;
  opacity: 0.75;
}
.react-flow__handle.connectable {
  cursor: crosshair;
  transform: scale(1.2);
  background: #f97b62;
}
.react-flow .react-flow__edges {
  overflow: visible;
  pointer-events: none;
  width: 100%;
}

@media screen and (max-width: 1208px), screen and (max-height: 720px) {
  .react-flow__handle.connectable {
    transform: scale(0.8) !important;
  }

  /* Junction */
  .junction-component > div:nth-child(-n + 8) {
    position: absolute;
    left: calc(100% - 8.9vw) !important;
  }

  .junction-component > div:nth-child(1),
  .junction-component > div:nth-child(5),
  .junction-component > div:nth-child(9),
  .junction-component > div:nth-child(13) {
    top: 4.2vh !important;
  }
  .junction-component > div:nth-child(2),
  .junction-component > div:nth-child(6),
  .junction-component > div:nth-child(10),
  .junction-component > div:nth-child(14) {
    top: 5.7vh !important;
  }
  .junction-component > div:nth-child(3),
  .junction-component > div:nth-child(7),
  .junction-component > div:nth-child(11),
  .junction-component > div:nth-child(15) {
    top: 7.2vh !important;
  }
  .junction-component > div:nth-child(4),
  .junction-component > div:nth-child(8),
  .junction-component > div:nth-child(12),
  .junction-component > div:nth-child(16) {
    top: 8.6vh !important;
  }

  /* power */

  .power-component > div:nth-child(1) {
    position: absolute;
    top: 4.7vh !important;
  }
  .power-component > div:nth-child(2) {
    top: 7.5vh !important;
  }
  .power-component > div:nth-child(3) {
    top: 9.1vh !important;
  }
  .power-component > div:nth-child(4) {
    top: 3vh !important;
  }

  /* beeper */

  .beeper-component > div:nth-child(2),
  .beeper-component > div:nth-child(3) {
    top: 5.4vh !important;
  }
  .beeper-component > div:nth-child(2) {
    left: 0.2vw !important;
  }
  /* led */

  .led-component > div:nth-child(2),
  .led-component > div:nth-child(3) {
    top: 5.5vh !important;
  }
  .led-component > div:nth-child(2) {
    left: 0.3vw !important;
  }
  /* tact */
  .tact-component > div:nth-child(1),
  .tact-component > div:nth-child(2) {
    top: 5.4vh !important;
  }
  .tact-component > div:nth-child(1) {
    left: 0.2vw !important;
  }
  /* pot */

  .pot-component > div:nth-child(1) {
    top: 12vh !important;
    left: 0.2vw !important;
  }
  .pot-component > div:nth-child(2) {
    top: 5.5vh !important;
    left: 0.25vw !important;
  }
  .pot-component > div:nth-child(3) {
    top: 3.2vh !important;
  }
  .pot-component > div:nth-child(4) {
    top: 7.8vh !important;
  }

  /* ldr */
  .ldr-component > div:nth-child(1) {
    top: 9vh !important;
    left: -1vw !important;
  }
  .ldr-component > div:nth-child(2),
  .ldr-component > div:nth-child(3) {
    top: 5.5vh !important;
  }
  .ldr-component > div:nth-child(2) {
    left: 0.2vw !important;
  }
  /* diode */

  .diode-component > :nth-child(1),
  .diode-component > :nth-child(3) {
    left: 0.5vw !important;
    /* border: 2px solid black; */
  }

  /* transistor */
  .transistor-component > :nth-child(1) {
    /* border: 1px solid red; */
    top: 6.1vh !important;
    left: 0.3vw !important;
  }
  .transistor-component > :nth-child(2) {
    top: 4.8vh !important;
  }
  .transistor-component > :nth-child(3) {
    top: 7.3vh !important;
  }
  /* res_100 */
  .res_100-component > :nth-child(1),
  .res_100-component > :nth-child(2) {
    top: 5.5vh !important;
  }
  .res_100-component > :nth-child(1) {
    left: 0.1vw !important;
  }
  /* res_250 */
  .res_250-component > :nth-child(1),
  .res_250-component > :nth-child(2) {
    top: 5.5vh !important;
  }
  .res_250-component > :nth-child(1) {
    left: 0.3vw !important;
  }

  /* capacitor100 */

  .capacitor100-component > :nth-child(1),
  .capacitor100-component > :nth-child(2) {
    top: 5.5vh !important;
  }
  .capacitor100-component > :nth-child(1) {
    left: 0.2vw !important;
  }

  /* capacitor1000 */
  .capacitor1000-component > :nth-child(1),
  .capacitor1000-component > :nth-child(2) {
    top: 5.5vh !important;
  }
  .capacitor1000-component > :nth-child(1) {
    left: 0.2vw !important;
  }
  .capacitor1000-component > :nth-child(2) {
    left: 10.1vw !important;
  }

  /* two_way_switch */

  .two_way_switch-component > div:nth-child(1) {
    top: 6vh !important;
    left: 0.25vw !important;
  }
  .two_way_switch-component > div:nth-child(2) {
    top: 4.3vh !important;
  }
  .two_way_switch-component > div:nth-child(3) {
    top: 7.8vh !important;
  }
}

@media screen and (max-width: 425px) {
  .react-flow__handle.connectable {
    transform: scale(0.5);
  }
}

/* @media only screen and (max-height:575.98px) and (orientation:landscape){
  .dndflow{
    height: 85vh;
  }
} */
.dummy {
  height: 15vh;
  pointer-events: none;
}
