.Parent-Container {
  /* height: 100%; */
  width: 100%;
}
:root {
  --main-radius: 5px;
  --main-paddind: 5px;
}

#nav {
  /* background-color: yellow; */
  grid-area: nav;
  border-radius: var(--main-radius);
  border-radius: var(--main-paddind);
  position: fixed;
  z-index: 5500;
  width: 100%;
  height: 27vh;
}

.container {
  margin-top: 27vh;
  position: absolute;
  width: 100%;
  display: grid;
  /* height: 100vh; */
  /* grid-template-columns: 33% 33% 33%; */
  /* grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)); */
  grid-template-rows: 10% 30% 30% 30%;
  grid-template-areas:
    "main main main"
    "content1 content1 content1"
    "content2 content2 content2"
    "content3 content3 content3";
}

#main {
  /* background-color: pink; */
  grid-area: main;
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
}

#main > img {
  width: 7vh;
  position: absolute;
  right: 35px;
  top: 27px;
}

#content1 {
  /* background-color: #f69f7d; */
  grid-area: content1;
  border-radius: var(--main-radius);
  border-radius: var(--main-paddind);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding: 10px 100px 10px 100px;
}
#content2 {
  /* background-color: #cbf5ad; */
  grid-area: content2;
  border-radius: var(--main-radius);
  border-radius: var(--main-paddind);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding: 10px 100px 10px 100px;
}
#content3 {
  /* background-color: #abd9e6; */
  grid-area: content3;
  border-radius: var(--main-radius);
  border-radius: var(--main-paddind);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding: 10px 100px 10px 100px;
}

.Text {
  font-family: "Halcyon_SemiBold";
  color: black;
  position: absolute;
  top: 65%;
  left: 49%;
  transform: translate(-50%, -50%);
  font-size: 2.5vh;
}
.Text1 {
  font-family: "Halcyon_SemiBold";
  color: black;
  position: absolute;
  margin: -40%;
  top: 120.5%;
  left: 90%;
  transform: translate(-50%, -50%);
  font-size: 2.5vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.help_text {
  position: absolute;
  top: 34%;
  left: 53%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
}
.help_textP {
  background-color: white;
  padding: 4px;
  font-size: 1.8vh;
  text-align: left;
  color: #707070;
  font-family: Halcyon_Regular;
  border-radius: 12px;
  margin-bottom: 0px;
}
.help_text1 {
  position: absolute;
  top: 30%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
}
.help_text2 {
  position: absolute;
  top: 24%;
  left: 24%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
}
.help_text3 {
  position: absolute;
  top: 54%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  width: 20%;
}
.help_text4 {
  position: absolute;
  top: 59%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  width: 16%;
}

.help_MainContainer {
  height: 100%;
  width: 100%;
}
.help_Container {
  position: fixed;
  top: 53vh;
  left: 53.5%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.help_Image {
  width: 90%;
}
.help_MainContainers {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 49.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1000000;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 30vh 30vh 30vh;
  background-color: #2196f3;
  padding: 1px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
} */

@media only screen and (orientation: portrait) {
  #turn {
    display: block;
    font-size: 7vh;
  }
  .Parent-Container {
    display: none;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media only screen and (orientation: landscape) {
  #turn {
    display: none;
  }
  .Parent-Container {
    display: block;
  }
}
.react-flow__attribution {
  visibility: hidden !important;
}
@font-face {
  font-family: "Halcyon_SemiBold";
  src: url("/src/Assets/Halcyon/Halcyon-SemiBold.otf");
}

@font-face {
  font-family: "Halcyon_Medium";
  src: url("/src/Assets/Halcyon/Halcyon-Medium.otf");
}

@font-face {
  font-family: "Halcyon_Regular";
  src: url("/src/Assets/Halcyon/Halcyon-Regular.otf");
}
